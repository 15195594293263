import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiAddLine, RiMenuLine, RiSubtractLine, RiTeamLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [transporterId, setTransporterId] = useState(null);
    const [shipments, setShipments] = useState([]);

    const [transporters, setTransporters] = useState([]);
    const [bag, setBag] = useState([]);

    useEffect(() => {
        handleGetAllCategories();
    }, [])

    const bagItems = useMemo(() => bag.filter(a => !shipments.includes(a.id)), [bag, shipments])

    const handleGetAllCategories = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/shipments/create");
            setTransporters(results?.transporters)
            setBag(results?.bag)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    const handleStore = async () => {
        try {
            setLoading(true)
            if (transporterId == null || shipments.length === 0) {
                throw Error("Lütfen belirtilen tüm alanları doldurun!")
            }
            await axios.post("/shipments", { transporter_id: transporterId, bag_ids: shipments });
            navigate(-1)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.message.toString() || error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    const handleAdd = (id) => {
        const findItem = shipments.findIndex(s => s === id)
        if (findItem === -1) {
            setShipments(prev => [...prev, id])
            return
        }
        setShipments(prev => prev.filter(p => p != id))
    }

    const handleRemove = (id) => {
        setShipments(prev => prev.filter(p => p != id))
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>SEVKİYAT YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SEVKİYAT OLUŞTUR</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/transports"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM SEVKİYATLAR</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Taşıyıcı:</span>
                                <Select
                                    size="md"
                                    placeholder='-'
                                    data={transporters}
                                    value={transporterId}
                                    onChange={e => setTransporterId(e)}
                                    clearable
                                    searchable
                                />
                            </div>
                        </div>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>BEKLEYEN SİPARİŞLER</h1>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            {bagItems?.length > 0 ? bagItems.map(b => {
                                return (
                                    <div className='col-span-12 grid grid-cols-12 gap-x-4 gap-y-3'>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>#</span>
                                            <Input value={b.id} size="sm" />
                                        </div>
                                        <div className='col-span-6'>
                                            <span className='font-light mb-1 block'>Ürün Adı:</span>
                                            <Input value={b.title} size="sm" />
                                        </div>
                                        <div className='col-span-3'>
                                            <span className='font-light mb-1 block'>Firma:</span>
                                            <Input value={b.client} size="sm" />
                                        </div>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>Miktar:</span>
                                            <Input value={`${b.qty} AD.`} size="sm" />
                                        </div>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>&nbsp;</span>
                                            <button onClick={() => handleAdd(b.id)} className='w-full h-9 bg-green-500 flex flex-row items-center justify-center text-sm text-white rounded'>
                                                <RiAddLine />
                                            </button>
                                        </div>
                                    </div>
                                )
                            }) : <span className='col-span-12 text-center text-sm font-light'>Siparişlerinizde onaylanmış hiç ürün bulunmuyor!</span>}
                        </div>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SEVKİYAT İÇERİĞİ</h1>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            {shipments?.length > 0 ? shipments?.map(s => {
                                const item = bag.find(b => b.id === s)
                                return (
                                    <div className='col-span-12 grid grid-cols-12 gap-x-4 gap-y-3'>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>#</span>
                                            <Input value={item.id} size="sm" />
                                        </div>
                                        <div className='col-span-6'>
                                            <span className='font-light mb-1 block'>Ürün Adı:</span>
                                            <Input value={item.title} size="sm" />
                                        </div>
                                        <div className='col-span-3'>
                                            <span className='font-light mb-1 block'>Firma:</span>
                                            <Input value={item.client} size="sm" />
                                        </div>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>Miktar:</span>
                                            <Input value={`${item.qty} AD.`} size="sm" />
                                        </div>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>&nbsp;</span>
                                            <button onClick={() => handleRemove(s)} className='w-full h-9 bg-red-500 flex flex-row items-center justify-center text-sm text-white rounded'><RiSubtractLine /></button>
                                        </div>
                                    </div>
                                )
                            }) : <span className='col-span-12 text-center text-sm font-light'>Sevkiyata hazırlanmış hiç ürün bulunmuyor!</span>}
                            <button onClick={handleStore} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                Oluştur!
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}