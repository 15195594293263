import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiMenuLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [entity, setEntity] = useState({ boundary: 5 });
    const [products, setProducts] = useState([]);

    useEffect(() => {
        handleCreate();
    }, [])

    const handleCreate = async () => {
        try {
            setLoading(true)
            const { data: result } = await axios.get(`/stations/create`)
            setProducts(result.products?.map(s => ({ label: `${s?.title}`, value: s?.id })))
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || error.message.toString() })
        } finally {
            setLoading(false)
        }
    }

    const handleStore = async () => {
        try {
            setLoading(true)
            await axios.post(`/stations?client_id=${params.id}`, entity)
            notifications.show({ title: "Başarılı", color: "green", message: "Kategori başarıyla oluşturuldu!" })
            navigate(-1)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || error.message.toString() })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>İSTASYON YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>İSTASYON OLUŞTUR</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <button onClick={() => navigate(-1)} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>İSTASYONLAR</button>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Kod:</span>
                                <Input value={entity.code} onChange={e => setEntity(prev => ({ ...prev, code: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Bölge:</span>
                                <Input value={entity.area} onChange={e => setEntity(prev => ({ ...prev, area: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>İçerik:</span>
                                <Select size="md" placeholder='-' searchable data={products} onChange={e => setEntity(prev => ({ ...prev, product_id: e }))} value={entity.product_id} />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Uyarı(%):</span>
                                <Input value={entity.boundary} onChange={e => setEntity(prev => ({ ...prev, boundary: e.target.value }))} size="md" />
                            </div>
                            <button onClick={handleStore} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                Oluştur!
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}