/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';

import axios from "helpers/axios";
// import useAuthStore from 'stores/auth';
import useAuthStore from 'stores/auth';

const Login = () => {

    const { setToken, setUser } = useAuthStore()

    useEffect(() => {
        setToken(null)
    }, [])

    const navigate = useNavigate()
    const [credentials, setCredentials] = useState({ username: "", password: "" });

    const handleLogin = async () => {
        try {
            const { data: login_result } = await axios.post(`/auth/login`, credentials);
            setToken(login_result?.token)
            setUser(login_result?.manager)
            navigate("/dashboard")
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-slate-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-sm w-full">
                <img className="mx-auto w-full px-16" src={"https://personel.fibacode.com/static/media/logo.637941aa41791e86651994199d81acd6.svg"} alt="" style={{ height: 60 }} />
                <form className="mt-8">
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input value={credentials?.username} onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))} required className="w-full px-4 py-4 border border-border font-medium focus:outline-none sm:text-sm sm:leading-5" placeholder="Kullanıcı Adı" />
                        </div>
                        <div className="-mt-px">
                            <input value={credentials?.password} onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))} type="password" required autoComplete='' className="w-full px-4 py-4 border border-border-0 placeholder-bg0 font-medium focus:outline-none sm:text-sm sm:leading-5" placeholder="Şifre" />
                        </div>
                    </div>

                    <div className="flex items-center justify-between my-4">
                        <div className="flex items-center">
                            <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-slate-800" />
                            <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-slate-800">Beni Hatırla</label>
                        </div>
                    </div>

                    <div className="cursor-pointer text-center w-full rounded-sm py-3 px-4 text-sm font-bold text-white bg-red-500 hover:bg-red-600 focus:outline-none" onClick={handleLogin}>
                        Giriş Yap!
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
