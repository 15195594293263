import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiMenuLine, RiTeamLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [transporters, setTransporters] = useState([]);
    const [bag, setBag] = useState([]);

    useEffect(() => {
        handleGetAllCategories();
    }, [])

    const handleGetAllCategories = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/shipments/create");
            setTransporters(results?.transporters)
            setBag(results?.bag)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    // const handleCreate = async () => {
    //     try {
    //         setLoading(true)
    //         if (!category.name) {
    //             throw Error("Lütfen tüm alanları eksiksiz doldurduğunuzdan emin olun!")
    //         }
    //         await axios.post("/categories", category)
    //         notifications.show({ title: "Başarılı", color: "green", message: "Kategori başarıyla oluşturuldu!" })
    //         navigate(-1)
    //     } catch (error) {
    //         notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || error.message.toString() })
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>SİPARİŞ YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SİPARİŞ OLUŞTUR</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/orders"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM SİPARİŞLER</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            ....
                            <button onClick={() => null} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                Oluştur!
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}