import './assets/index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

import Navigation from './navigation';
import 'moment/locale/tr';
import { Connector } from 'lib';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MantineProvider>
      <ModalsProvider>
        <Notifications position="top-right" zIndex={1000} limit={5} autoClose={3500} />
        <Navigation />
      </ModalsProvider>
    </MantineProvider>
  </BrowserRouter>
);