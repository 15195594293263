import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiMenuLine, RiTeamLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const navigate = useNavigate();
    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [entity, setEntity] = useState({});

    useEffect(() => {
        handleFindItem();
    }, [])

    const handleFindItem = async () => {
        try {
            setLoading(true)
            const { data: result } = await axios.get(`/transporters/${params.id}`)
            setEntity(result.transporter);
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || error.message.toString() })
        } finally {
            setLoading(false)
        }
    }

    const handleUpdate = async () => {
        try {
            setLoading(true)
            await axios.post(`/clients/${params.id}`, entity)
            notifications.show({ title: "Başarılı", color: "green", message: "Taşıyıcı başarıyla güncellendi!" })
            navigate(-1)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || error.message.toString() })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>TAŞIYICI YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>TAŞIYICI DÜZENLE</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/transporters"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM TAŞIYICILAR</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Müşteri Adı:</span>
                                <Input value={entity.title} onChange={e => setEntity(prev => ({ ...prev, title: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>E-Posta:</span>
                                <Input value={entity.email} onChange={e => setEntity(prev => ({ ...prev, email: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Şifre: <small>&mdash; değiştirmek istemezseniz boş bırakın!</small></span>
                                <Input value={entity.password} onChange={e => setEntity(prev => ({ ...prev, password: e.target.value }))} size="md" />
                            </div>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Durum:</span>
                                <Select size="md" placeholder='-' data={[{ value: true, label: "Aktif" }, { value: false, label: "Pasif" }]} onChange={e => setEntity(prev => ({ ...prev, is_active: e }))} value={entity.is_active} />
                            </div>
                            <button onClick={handleUpdate} className='col-span-12 bg-blue-500 h-12 rounded text-white font-light'>
                                Düzenle!
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}