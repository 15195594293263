import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

//stores
import useAuthStore from 'stores/auth';

//components
import Header from 'layouts/header';
import Footer from 'layouts/footer';

//pages
import Login from 'pages/auth/Login';
import Dashboard from 'pages/Dashboard';

import ProductList from 'pages/products/List';
import ProductCreate from 'pages/products/Create';
import ProductEdit from 'pages/products/Edit';

import OrderList from 'pages/orders/List';
import OrderCreate from 'pages/orders/Create';
import OrderItems from 'pages/orders/Edit';

import PageList from 'pages/pages/List';
import PageEdit from 'pages/pages/Edit';

import ClientList from 'pages/clients/List';
import ClientCreate from 'pages/clients/Create';
import ClientEdit from 'pages/clients/Edit';

import ClientGasIndex from 'pages/stations/Index';
import ClientGasCreate from 'pages/stations/Create';
import ClientGasEdit from 'pages/stations/Edit';

import TransportList from 'pages/transports/List';
import TransportCreate from 'pages/transports/Create';
import TransportEdit from 'pages/transports/Edit';

import TransportersList from 'pages/transporters/List';
import TransportersCreate from 'pages/transporters/Create';
import TransportersEdit from 'pages/transporters/Edit';

const Navigation = () => {

    const { token } = useAuthStore()

    const PrivateRoute = () => {
        if (!token) <Navigate to={"/"} replace />
        return (
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        )
    }

    return (
        <Routes>
            <Route element={<PrivateRoute />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='products'>
                    <Route path='' element={<ProductList />} />
                    <Route path='create' element={<ProductCreate />} />
                    <Route path=':id' element={<ProductEdit />} />
                </Route>
                <Route path='orders'>
                    <Route path='' element={<OrderList />} />
                    <Route path='create' element={<OrderCreate />} />
                    <Route path=':id' element={<OrderItems />} />
                </Route>
                <Route path='clients'>
                    <Route path='' element={<ClientList />} />
                    <Route path='create' element={<ClientCreate />} />
                    <Route path=':id' element={<ClientEdit />} />

                    <Route path=':id/gas' element={<ClientGasIndex />} />
                    <Route path=':id/gas/create' element={<ClientGasCreate />} />
                    <Route path=':id/gas/:gas' element={<ClientGasEdit />} />

                </Route>
                <Route path='transports'>
                    <Route path='' element={<TransportList />} />
                    <Route path='create' element={<TransportCreate />} />
                    <Route path=':id' element={<TransportEdit />} />
                </Route>
                <Route path='pages'>
                    <Route path='' element={<PageList />} />
                    <Route path=':id' element={<PageEdit />} />
                </Route>
                <Route path='transporters'>
                    <Route path='' element={<TransportersList />} />
                    <Route path='create' element={<TransportersCreate />} />
                    <Route path=':id' element={<TransportersEdit />} />
                </Route>
            </Route>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Navigate to={"/"} replace />} />
        </Routes>
    );
}

export default Navigation