import React, { useEffect, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { LoadingOverlay } from '@mantine/core';
import { RiArrowRightLine, RiEditLine, RiMenuLine } from "react-icons/ri";

import DataTable from 'components/table';

import { Link } from 'react-router-dom';
import axios from 'helpers/axios';
import { notifications } from '@mantine/notifications';

export default function OrderList() {

    const columnHelper = createColumnHelper()

    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        handleGetAllCampaigns();
    }, [])

    const handleGetAllCampaigns = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/transporters");
            setCategories(results?.transporters)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        columnHelper.accessor('id', {
            header: '#',
        }),
        columnHelper.accessor('title', {
            header: 'MÜŞTER ADI',
            truncate: true,
            full: true,
        }),
        columnHelper.accessor('email', {
            header: 'E-POSTA',
            truncate: true,
        }),
        columnHelper.accessor('created_at', {
            header: 'OLUŞ. TARİHİ',
            truncate: true,
        }),
        columnHelper.accessor('id', {
            header: '',
            cell: row => {
                return (
                    <div className='w-max grid grid-cols-1 gap-x-2'>
                        <Link to={`/transporters/${row.getValue()}`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                            <RiEditLine color="white" size={16} />
                        </Link>
                    </div>
                )
            }
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>TAŞIYICI YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>TAŞIYICI LİSTESİ</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/transporters/create"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TAŞIYICI OLUŞTUR</Link>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable columns={columns} searchable data={categories} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}