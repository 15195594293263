import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { LoadingOverlay } from '@mantine/core';

import axios_client from 'helpers/axios';
import { notifications } from '@mantine/notifications';
import { RiArrowRightLine } from 'react-icons/ri';
import mqttService from 'lib/connector';
import DataTable from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';

export default function Dashboard() {

    const columnHelper = createColumnHelper()
    const [loading, setLoading] = useState(false);

    const [numbers, setNumbers] = useState({});
    const [stations, setStations] = useState([]);

    const [client, setClient] = useState(null);

    const columns = [
        columnHelper.accessor('code', {
            header: '#',
        }),
        columnHelper.accessor('client_title', {
            header: 'MÜŞTERİ ADI',
            truncate: true,
        }),
        columnHelper.accessor('product_title', {
            header: 'ÜRÜN ADI',
            truncate: true,
        }),
        columnHelper.accessor('area', {
            header: 'BÖLGE',
            truncate: true,
        }),
        columnHelper.accessor('pressure', {
            header: 'DURUM',
            full: true,
            progress: true
        }),
        columnHelper.accessor('client_id', {
            header: '',
            cell: row => {
                return (
                    <div className='w-max grid grid-cols-1 gap-x-2'>
                        <Link to={`/clients/${row.getValue()}/gas`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                            <RiArrowRightLine color="white" size={16} />
                        </Link>
                    </div>
                )
            }
        }),
    ]

    useEffect(() => {
        const client = mqttService.getClient(null);
        setClient(client);
        mqttService.onMessage(client, (payload) => {
            setStations(prev => prev.map(p => p.code === payload.topic ? ({ ...p, pressure: payload.pressure, updated_at: new Date() }) : p))
        });
        return () => mqttService.closeConnection(client);
    }, []);

    useEffect(() => {
        if (client) {
            mqttService.subscribe(client, "#", null);
        }
        handleGetDashboardData();
    }, [client])

    const handleGetDashboardData = async () => {
        try {
            const { data: dashboard_result } = await axios_client.get("/");
            setNumbers(dashboard_result?.numbers || {})
            setStations(dashboard_result?.stations || [])
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 p-6'>

                <div>
                    <div className='flex flex-row items-center justify-between mb-4'>
                        <h2 className="text-xl uppercase font-semibold text-slate-700">
                            GENEL İSTATİSTİKLER
                        </h2>
                    </div>

                    <div className="grid grid-cols-12 grid-rows-1 gap-x-6 gap-y-6">

                        <Link to={{ pathname: "/orders" }} className="col-span-6 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.order_count ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                Sipariş
                            </strong>
                        </Link>
                        <Link to={{ pathname: "/transports" }} className="col-span-6 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.shipment_count ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                Sevkiyat
                            </strong>
                        </Link>

                        <Link to={{ pathname: "/clients" }} className="col-span-4 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.client_count ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                Müşteri
                            </strong>
                        </Link>
                        <Link to={{ pathname: "/products" }} className="col-span-4 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.product_count ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                Ürün
                            </strong>
                        </Link>
                        <Link to={{ pathname: "/pages" }} className="col-span-4 bg-white flex flex-col justify-center rounded text-center py-6 shadow-sm">
                            <span className="text-slate-700 text-xl font-semibold">{numbers?.page_count ?? 0}</span>
                            <strong className="font-light text-slate-500 text-lg">
                                Sayfa
                            </strong>
                        </Link>

                    </div>
                </div>

                <div>
                    <div className='flex flex-row items-center justify-between mb-4'>
                        <h2 className="text-xl uppercase font-semibold text-slate-700">
                            İSTASYONLAR
                        </h2>
                    </div>

                    <div className='bg-white'>
                        <DataTable columns={columns} data={stations} style sort={"code"} searchable />

                        {/* {stations?.map(s => {
                            let stationPercent = ((s?.pressure * 100) / 200);
                            return (
                                <div className='bg-white shadow-sm flex flex-row items-center justify-between px-[12px] border-b border-b-slate-100 cursor-pointer'>
                                    <div className='flex py-[12px]'>
                                        <h2 className='text-base font-bold leading-5'>{s.client_title}<small className='font-light'> - {s.product_title} ({s.code})</small></h2>
                                    </div>
                                    <div className='flex flex-row gap-x-3'>
                                        <div className='relative w-[250px] h-[22px] rounded text-[12px] text-white font-light overflow-hidden bg-gray-100 flex flex-row items-center justify-start'>
                                            <div className='absolute left-0 top-0 bottom-0 w-[25%] bg-red-700' />
                                            <span className='relative px-3 text-xs'>{stationPercent?.toFixed(1)}%</span>
                                        </div>
                                        <div className='w-[22px] h-[22px] bg-blue-500 rounded flex flex-row items-center justify-center'>
                                            <RiArrowRightLine size={10} color='white' />
                                        </div>
                                    </div>
                                </div>
                            )
                        })} */}
                    </div>
                </div>

            </div>

        </div>
    );
}
