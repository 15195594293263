import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Input, LoadingOverlay, Select } from '@mantine/core';
import { RiAddLine, RiMenuLine, RiSubtractLine, RiTeamLine } from "react-icons/ri";
import { notifications } from '@mantine/notifications';

import axios from 'helpers/axios';

export default function OrderList() {

    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [transporters, setTransporters] = useState([]);
    const [bag, setBag] = useState([]);
    const [shipment, setShipment] = useState({});

    useEffect(() => {
        handleGetAllCategories();
    }, [])

    const handleGetAllCategories = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get(`/shipments/${params.id}`);
            setTransporters([results?.transporter])
            setBag(results?.bag)
            setShipment(results?.shipment)
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        } finally {
            setLoading(false)
        }
    }


    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiMenuLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>SEVKİYAT YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 p-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SEVKİYAT OLUŞTUR</h1>
                            </div>
                            <div className='flex flex-row -mx-1'>
                                <Link to={"/transports"} className='bg-green-500 mx-1 px-4 py-2 rounded text-sm text-white'>TÜM SEVKİYATLAR</Link>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>Teslimat Kodu:</span>
                                <Input disabled value={shipment.code} size="md" />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Taşıyıcı:</span>
                                <Select
                                    size="md"
                                    placeholder='-'
                                    data={transporters}
                                    value={transporters[0]?.value}
                                    disabled
                                />
                            </div>
                            <div className='col-span-6'>
                                <span className='font-light mb-1 block'>Oluşturulma Tarihi:</span>
                                <Input disabled value={shipment.created_at} size="md" />
                            </div>
                        </div>
                    </div>


                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>SEVKİYAT İÇERİĞİ</h1>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>
                            {bag?.length > 0 ? bag.map(b => {
                                return (
                                    <div className='col-span-12 grid grid-cols-12 gap-x-4 gap-y-3'>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>#</span>
                                            <Input value={b.id} size="sm" />
                                        </div>
                                        <div className='col-span-5'>
                                            <span className='font-light mb-1 block'>Ürün Adı:</span>
                                            <Input value={b.title} size="sm" />
                                        </div>
                                        <div className='col-span-3'>
                                            <span className='font-light mb-1 block'>Firma:</span>
                                            <Input value={b.client} size="sm" />
                                        </div>
                                        <div className='col-span-1'>
                                            <span className='font-light mb-1 block'>Miktar:</span>
                                            <Input value={`${b.qty} AD.`} size="sm" />
                                        </div>
                                        <div className='col-span-2'>
                                            <span className='font-light mb-1 block'>Teslimat:</span>
                                            <Input value={b.is_over ? "[E]" : "[H]"} size="sm" />
                                        </div>
                                    </div>
                                )
                            }) : <span>asd</span>}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}