import mqtt from "mqtt";

const websocketUrl = "wss://mqtt.fibacode.com/ws";

function getClient() {
    const client = mqtt.connect(websocketUrl);
    client.stream.on("error", (err) => {
        client.end();
    });
    return client;
}

function subscribe(client, topic) {
    const callBack = (err) => {
        if (err) {
        }
    };
    return client.subscribe(topic, callBack);
}

function onMessage(client, callBack) {
    client.on("message", (topic, message) => {
        callBack({ topic: topic, pressure: JSON.parse(new TextDecoder("utf-8").decode(message)) });
    });
}

function unsubscribe(client, topic) {
    client.unsubscribe(topic);
}

function closeConnection(client) {
    client.end();
}

const mqttService = {
    getClient,
    subscribe,
    onMessage,
    unsubscribe,
    closeConnection,
};
export default mqttService;