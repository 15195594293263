import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { useFullscreen } from '@mantine/hooks';

import { RiLogoutBoxLine, RiFileMarkLine, RiTeamLine, RiFullscreenLine, RiFullscreenExitLine, RiBookLine, RiMenuLine } from "react-icons/ri";

import useAuthStore from 'stores/auth';
import axios from '../helpers/axios';

const Header = () => {

    const location = useLocation();
    const { toggle, fullscreen } = useFullscreen();

    const { setToken, seller } = useAuthStore();


    const active_menu = (current_path) => {
        return `border-b-4 border-transparent h-16 flex flex-row align-center items-center hover:border-red-600 ${!location.pathname.indexOf(current_path) && "border-red-600"}`;
    }

    return (
        <div className="bg-white shadow-sm relative z-50">
            <div className="container mx-auto">

                <div className="flex flex-row justify-between py-6">
                    <div className="flex flex-col justify-center">
                        <Link to="/dashboard"><img src={"https://personel.fibacode.com/static/media/logo.637941aa41791e86651994199d81acd6.svg"} alt="" style={{ height: 55 }} /></Link>
                    </div>
                    <div className="flex flex-col justify-center">
                        <div className="flex flex-row-reverse justify-center relative items-center -mx-1">

                            <Link to="/pages" className="el-tooltip w-10 h-10 bg-blue-600 rounded flex flex-col justify-center items-center cursor-pointer item mx-1">
                                <RiFileMarkLine color="white" size={18} />
                            </Link>

                            <div onClick={() => {
                                setToken(null)
                                delete axios.defaults.headers.authorization;
                                window.location.href = "/login"
                            }} className="el-tooltip w-10 h-10 bg-red-600 rounded flex flex-col justify-center items-center cursor-pointer item mx-1">
                                <RiLogoutBoxLine color="white" size={18} />
                            </div>

                            <div onClick={toggle} className="el-tooltip w-10 h-10 bg-slate-200 rounded flex flex-col justify-center items-center cursor-pointer mx-1 item">
                                {fullscreen ? <RiFullscreenExitLine color="black" size={18} /> : <RiFullscreenLine color="black" size={18} />}
                            </div>

                            <div className="flex flex-col justify-center text-right mx-2 text-lg">
                                <div className="font-bold text-base leading-5 uppercase">{seller?.fullname}</div>
                                <div className="font-light text-base leading-5">{seller?.title}</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="menu flex flex-row border-t border-slate-100">
                    <div className="h-16 flex-auto">
                        <Link to="/products" className={active_menu('/products')}>
                            <RiBookLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">ÜRÜN YÖNETİMİ</span>
                        </Link>
                    </div>
                    <div className="h-16 flex-auto relative">
                        <Link to="/clients" className={active_menu('/clients')}>
                            <RiTeamLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">MÜŞTERİ YÖNETİMİ</span>
                        </Link>
                    </div>
                    <div className="h-16 flex-auto">
                        <Link to="/orders" className={active_menu('/orders')}>
                            <RiMenuLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">SİPARİŞ YÖNETİMİ</span>
                        </Link>
                    </div>
                    <div className="h-16 flex-auto">
                        <Link to="/transports" className={active_menu('/transports')}>
                            <RiBookLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">SEVKİYAT YÖNETİMİ</span>
                        </Link>
                    </div>
                    <div className="h-16 flex-auto">
                        <Link to="/transporters" className={active_menu('/transporters')}>
                            <RiFileMarkLine color="gray" size={18} />
                            <span className="text-slate-800 pl-2 font-light text-base">TAŞIYICI YÖNETİMİ</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
