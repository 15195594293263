import { useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import axios_client from 'helpers/axios';

const RichEditor = ({ value, setValue }) => {

    const editorRef = useRef(null);
    const onEditorInputChange = (newValue, editor) => {
        setValue(newValue);
    }

    return (
        <Editor
            onInit={(evt, editor) => {
                editorRef.current = editor
                setValue(value);
            }}
            initialValue=""
            apiKey='cqh7ahlhb3dhv7nlbsbtogpttisqbwwu4te01yg9gg9vbkxi'
            plugins={['image', 'table']}
            onEditorChange={onEditorInputChange}
            value={value}
            init={{
                height: 400,
                menubar: false,
                image_uploadtab: true,
                image_file_types: "image",
                images_upload_handler: async (blobInfo, progress) => {
                    try {
                        const formData = new FormData();
                        formData.append('file', blobInfo.blob(), blobInfo.filename());
                        formData.append('folder', "editor");
                        const { data: upload_response } = await axios_client.post("/uploads", formData, {
                            headers: { "Content-type": "multipart/form-data" },
                        });
                        return upload_response.url
                    } catch (error) {
                        alert("olmadı!")
                    }
                },
                plugins: [
                    'autolink', 'link', 'lists', 'image', 'charmap', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'image'
                ],
                toolbar: 'code fullscreen | bold italic underline align | blocks fontsize | link image media table | numlist bullist | removeformat',
            }}
        />
    )

}

export default RichEditor