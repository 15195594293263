import React from 'react';

const Footer = () => {
    return (
        <div className="bg-white border-t-1 border-ddd py-6">
            <div className="container mx-auto flex justify-between">
                <span className="text-base">&copy;2024 &mdash; fibacode.com</span>
                <div className='flex flex-row gap-x-2'>
                    <span className="font-light text-base cursor-pointer mx-3" onClick={() => alert("Yapım aşamasında..")}>API Dokümanları</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;